import React from 'react';
import HomeLayout from'./component/pages/home';
import Navbar from'./component/common/navbar';
import './css/home.css';

function App() {
  return (
    <>
    <Navbar />
    <HomeLayout />
    </>
  
  );
}

export default App;
